import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";
import { FirestoreDocumentData } from "./common";
import { WorkerProfile } from "./WorkerProfiles";

export interface InternalMessageData extends FirestoreDocumentData {
  body: string | null;
  createdOn: firebase.firestore.Timestamp | null;
  senderId: string | null;
  status: string | null;
}

const __cache: Record<string, InternalMessage> = {};

export class InternalMessage {
  mPath: string;
  mData: InternalMessageData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;
  mSender: WorkerProfile | null | undefined;

  constructor(path: string, data?: InternalMessageData) {
    makeAutoObservable(this);

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "sender", this.subscribeSender);
    onBecomeUnobserved(this, "sender", this.unsubscribeSender);
  }

  static createOrUpdateInstance(path: string, data?: InternalMessageData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new InternalMessage(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: InternalMessageData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(internalMessageDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(internalMessageDataConverter)
        .onSnapshot(
          action(
            (
              snapshot: firebase.firestore.DocumentSnapshot<InternalMessageData>
            ) => {
              const data = snapshot.data();
              this.mData = data;
            }
          )
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get body(): string | null | undefined {
    if (this.data) return this.data.body;
    return undefined;
  }

  get createdOn(): Date | null | undefined {
    if (this.data) return this.data.createdOn?.toDate();
    return undefined;
  }

  get senderId(): string | null | undefined {
    if (this.data) return this.data.senderId;
    return undefined;
  }

  get status(): string | null | undefined {
    if (this.data) return this.data.status;
    return undefined;
  }

  get sender(): WorkerProfile | null | undefined {
    return this.mSender;
  }
  subscribeSender = () => {
    when(
      () => this.senderId !== undefined,
      () => {
        if (this.mSender === undefined && this.senderId !== undefined) {
          runInAction(() => {
            this.mSender = this.senderId
              ? WorkerProfile.createOrUpdateInstance(
                  `workerProfiles/${this.senderId}`
                )
              : null;
            this.mSender?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeSender = () => {
    this.mSender?.unsubscribe();
    this.mSender = undefined;
  };
}

export const internalMessageDataConverter = {
  toFirestore: function (data: InternalMessageData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<InternalMessageData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      body: data.body ?? null,
      createdOn: data.createdOn ?? null,
      senderId: data.senderId ?? null,
      status: "sent",
    } as InternalMessageData;
  },
};

export const internalMessageConverter = {
  toFirestore: function (message: InternalMessage) {
    return message.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<InternalMessageData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return InternalMessage.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      body: data.body ?? null,
      createdOn: data.createdOn ?? null,
      senderId: data.senderId ?? null,
      status: "sent",
    });
  },
};

export class InternalMessages {
  mCollectionRef: firebase.firestore.CollectionReference<InternalMessage>;
  mQuery: firebase.firestore.Query<InternalMessage> | null;
  mPath: string;
  mData: InternalMessage[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(internalMessageConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<InternalMessage> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action(
          (snapshot: firebase.firestore.QuerySnapshot<InternalMessage>) => {
            const data = snapshot.docs.map((doc) => doc.data());
            this.mData = data;
          }
        )
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get().then(
        action(
          (snapshot: firebase.firestore.QuerySnapshot<InternalMessage>) => {
            const data = snapshot.docs.map((doc) => doc.data());
            this.mData = data;
          }
        )
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef).onSnapshot(
        action(
          (snapshot: firebase.firestore.QuerySnapshot<InternalMessage>) => {
            const data = snapshot.docs.map((doc) => doc.data());
            this.mData = data;
          }
        )
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
