import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonIcon,
  IonThumbnail,
  IonSkeletonText,
  IonBadge,
} from "@ionic/react";

import {
  peopleOutline,
  personOutline,
  people,
  person,
  chatbubblesOutline,
  cog,
  personCircle,
  chatbubbles,
} from "ionicons/icons";
import moment from "moment";

import { observer } from "mobx-react-lite";
import { WorkItem } from "../models/WorkItems";

import session from "../session";
import React from "react";
import { Conversation } from "../models/Conversations";

interface ConversationListItemProps {
  conversation: Conversation;
}

const ConversationListItem: React.FC<ConversationListItemProps> = observer(
  ({ conversation }) => {
    if (
      conversation &&
      conversation.loaded &&
      conversation.lastMessage &&
      conversation.lastMessage.loaded &&
      conversation.customer &&
      conversation.customer.loaded
    ) {
      const customerName =
        conversation.customer.fullName ?? conversation.customer.mobileNumber;
      const lastMessage = conversation.lastMessage.body;
      return (
        <IonItem>
          <IonAvatar
            slot="start"
            style={{
              height: "50px",
              width: "50px",
            }}
          >
            <IonIcon
              icon={personCircle}
              color="primary"
              style={{ fontSize: "50px" }}
            ></IonIcon>
          </IonAvatar>
          <IonLabel>
            <h2>
              <b>{customerName}</b>
            </h2>
            <p>{lastMessage}</p>
          </IonLabel>
          <IonButton
            slot="end"
            onClick={() =>
              session.currentUser?.workerProfile?.acceptAvailableConversation()
            }
          >
            Accept
          </IonButton>
        </IonItem>
      );
    }

    return (
      <IonItem lines="full">
        <IonAvatar
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={personCircle}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "50%" }} slot="start" />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: "90%" }} />
          </p>
        </IonLabel>
      </IonItem>
    );
  }
);

export default ConversationListItem;
