import React from "react";
import { observer } from "mobx-react-lite";

import session from "../session";

export interface AuthCheckProps {
  fallback: React.ReactNode;
}

const AuthCheck: React.FC<AuthCheckProps> = observer(
  ({ children, fallback }) => {
    if (session.signedIn) {
      return <>{children}</>;
    } else {
      return <>{fallback}</>;
    }
  }
);

export default AuthCheck;
