import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonIcon,
  IonThumbnail,
  IonSkeletonText,
  IonBadge,
} from "@ionic/react";

import {
  peopleOutline,
  personOutline,
  people,
  person,
  chatbubblesOutline,
  cog,
  personCircle,
  chatbubbles,
} from "ionicons/icons";
import moment from "moment";

import { observer } from "mobx-react-lite";
import { WorkItem } from "../models/WorkItems";

import session from "../session";
import React from "react";

interface WorkItemListItemProps {
  workItem: WorkItem;
}

const WorkItemListItem: React.FC<WorkItemListItemProps> = observer(
  ({ workItem }) => {
    if (
      workItem.conversation &&
      workItem.conversation.loaded &&
      workItem.conversation.customer &&
      workItem.conversation.customer.loaded &&
      workItem.conversation.lastMessage &&
      workItem.conversation.lastMessage.loaded &&
      ((workItem.conversation.lastMessage.type === "agent" &&
        workItem.conversation.lastMessage.workerProfile &&
        workItem.conversation.lastMessage.workerProfile.loaded) ||
        workItem.conversation.lastMessage.type !== "agent")
    ) {
      const customerName =
        workItem.conversation.customer.fullName ??
        workItem.conversation.customer.mobileNumber;
      const messageDate = moment(
        workItem.conversation.lastMessage.createdOn
      ).fromNow();
      const lastMessageSender =
        workItem.conversation.lastMessage.type === "agent"
          ? workItem.conversation.lastMessage.workerProfile?.id ===
            session.currentUser?.workerProfile?.id
            ? "You: "
            : `${workItem.conversation.lastMessage.workerProfile?.name}: `
          : workItem.conversation.lastMessage.type === "system"
          ? "System: "
          : "";
      const lastMessage = workItem.conversation.lastMessage.body;
      const hasUnread = workItem.unreadMessages;
      return (
        <IonItem routerLink={`/conversations/${workItem.id}`} detail={false}>
          <IonAvatar
            slot="start"
            style={{
              height: "50px",
              width: "50px",
            }}
          >
            <IonIcon
              icon={personCircle}
              color="primary"
              style={{ fontSize: "50px" }}
            ></IonIcon>
          </IonAvatar>
          <IonLabel>
            <h2>
              <b>{customerName}</b>
              <span className="ion-text-end ion-float-right">
                <IonNote>{messageDate}</IonNote>
              </span>
            </h2>
            <p>
              <b>{lastMessageSender}</b>
              {lastMessage}
            </p>
          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem lines="full">
        <IonAvatar
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={personCircle}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "50%" }} slot="start" />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: "90%" }} />
          </p>
        </IonLabel>
      </IonItem>
    );
  }
);

export default WorkItemListItem;
