import { RouteComponentProps, useHistory } from "react-router";
import React, { useEffect, useState, useCallback } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonImg,
  IonInput,
  IonButton,
  IonButtons,
  IonIcon,
  IonSpinner,
  IonProgressBar,
  CreateAnimation,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonItem,
  IonLabel,
  IonAlert,
  IonModal,
} from "@ionic/react";
import { closeOutline, logoGoogle, logoFacebook } from "ionicons/icons";
import { useForm, Controller } from "react-hook-form";

import session from "../session";
import { observer } from "mobx-react-lite";

let initialValues = {
  email: "",
  password: "",
};

const debug = false;

const LoginPage: React.FC = observer(() => {
  const history = useHistory();

  if (debug) console.log("Component: Render - UserLogin");

  useEffect(() => {
    if (debug) console.log("Component: Activate - UserLogin");
    return function dispose() {
      if (debug) console.log("Component: Dispose - UserLogin");
    };
  }, []);

  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoginBusy, setIsLoginBusy] = useState(false);
  const [showForgotPasswordAlert, setShowForgotPasswordAlert] = useState(false);
  const [email, setEmail] = useState("");

  const { control, handleSubmit, formState, reset, errors } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    setIsLoginBusy(true);

    session
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        reset();
        history.push("/");
      })
      .catch(function (error) {
        // Handle Errors here.
        //var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        setErrorMessage(errorMessage);
        setShowErrorToast(true);
      })
      .finally(() => setIsLoginBusy(false));
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonTitle>Sign In</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonProgressBar hidden={!isLoginBusy} type="indeterminate" />
      <IonContent>
        <IonToast
          color="medium"
          isOpen={showErrorToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={errorMessage}
          duration={2000}
        />

        <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 18 }}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        type="email"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Email"
                      />
                    )}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <Controller
                    control={control}
                    name="password"
                    rules={{
                      required: true,
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        type="password"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Password"
                      />
                    )}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem
                  color="clear"
                  lines="none"
                  onClick={() => setShowForgotPasswordAlert(true)}
                >
                  <IonLabel>
                    <div className="ion-text-end">
                      <p>Forgot password?</p>
                    </div>
                  </IonLabel>
                  <IonAlert
                    isOpen={showForgotPasswordAlert}
                    onDidDismiss={() => setShowForgotPasswordAlert(false)}
                    header="Reset password"
                    inputs={[
                      {
                        name: "email",
                        type: "email",
                        handler: (input) => alert(input.value),
                        placeholder: "email address",
                        attributes: {
                          required: true,
                        },
                      },
                    ]}
                    buttons={[
                      {
                        text: "Cancel",
                        role: "cancel",
                        cssClass: "secondary",
                      },
                      {
                        text: "Send",
                        handler: () => alert(email),
                      },
                    ]}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  type="submit"
                  disabled={!formState.isValid || isLoginBusy}
                  expand="block"
                >
                  {isLoginBusy ? "Signing in..." : "Sign in"}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow hidden style={{ marginTop: "20px", marginBottom: "10px" }}>
              <IonCol>
                <IonLabel>
                  <p className="ion-text-center">Or sign in with</p>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  color="light"
                  expand="block"
                  onClick={() => {
                    session.signInWithGoogle().then(() => {
                      reset();
                      history.push("/");
                    });
                  }}
                >
                  <IonIcon icon={logoGoogle} slot="start"></IonIcon>
                  Google
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton shape="round" color="light" expand="block">
                  <IonIcon icon={logoFacebook} slot="start"></IonIcon>
                  Facebook
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "20px", marginBottom: "20px" }}>
              <IonCol>
                <IonLabel onClick={() => history.push("/signup")}>
                  <p className="ion-text-center">
                    Don't have an account? <strong>Sign up here.</strong>
                  </p>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
});

export default LoginPage;
