import React, { useEffect, useState } from "react";
import {
  IonIcon,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  IonProgressBar,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { checkmark } from "ionicons/icons";

import WorkItemListItem from "../components/WorkItemListItem";

import session from "../session";
import CustomerListItem from "../components/CustomerListItem";

const CustomersPage: React.FC = observer(() => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonTitle>Customers</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">Customers</IonTitle>
          </IonToolbar>
        </IonHeader>*/}

        <IonProgressBar
          hidden={!session.customers?.loading}
          type="indeterminate"
        />

        <IonList hidden={!session.customers?.loaded}>
          {session.customers?.loaded &&
            session.customers?.data?.map((item) => (
              <CustomerListItem key={item.id} customer={item} />
            ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default CustomersPage;
