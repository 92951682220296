import React, { useEffect, useState } from "react";
import {
  IonIcon,
  IonButton,
  IonItem,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  IonListHeader,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { checkmark } from "ionicons/icons";

import session from "../session";

const SettingsPage: React.FC = observer(() => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>*/}

        <IonList>
          <IonListHeader></IonListHeader>
          <IonItem button lines="none" onClick={() => session.signOut()}>
            <IonLabel className="ion-text-center">Sign Out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
});

export default SettingsPage;
