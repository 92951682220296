import React, { useEffect, useState } from "react";
import {
  IonItemDivider,
  IonFooter,
  IonIcon,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  IonListHeader,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { checkmark } from "ionicons/icons";

import WorkItemListItem from "../components/WorkItemListItem";

import session from "../session";
import { Customer, Customers } from "../models/Customers";
import ConversationListItem from "../components/ConversationListItem";

const ConversationsPage: React.FC = observer(() => {
  const [segment, setSegment] = useState<string | undefined>(
    "openConversations"
  );
  const [
    closedConversationsInitalized,
    setClosedConversationsInitalized,
  ] = useState(false);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonTitle>Conversations</IonTitle>
        </IonToolbar>
        <IonToolbar color="primary">
          <IonSegment
            
            onIonChange={(e) => {
              setSegment(e.detail.value)
              if (e.detail.value == "closedConversations") {
                setClosedConversationsInitalized(true)
              }
            }}
            value={segment}
          >
            <IonSegmentButton value="openConversations">
              <IonLabel>Open</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="closedConversations" layout="icon-start">
              <IonLabel>Closed</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">
          <IonToolbar color="primary">
            <IonTitle size="large">Conversations</IonTitle>
          </IonToolbar>
        </IonHeader>*/}

        

        <IonProgressBar
          hidden={!session.currentUser?.workerProfile?.openWorkItems?.loading}
          type="indeterminate"
        />

        <IonList hidden={segment !== "openConversations"}>
          <IonItemDivider
            hidden={
              !session.currentUser?.workerProfile?.isAvailableConversation
            }
          >
            <IonLabel>
              <h2>New:</h2>
            </IonLabel>
          </IonItemDivider>

          {session.currentUser?.workerProfile?.availableConversations?.loaded &&
            session.currentUser?.workerProfile?.availableConversations?.data?.map(
              (item) => (
                <ConversationListItem key={item.id} conversation={item} />
              )
            )}

          <IonItemDivider>
            <IonLabel>
              <h2>My conversations:</h2>
            </IonLabel>
          </IonItemDivider>

          {session.currentUser?.workerProfile?.openWorkItems?.loaded &&
            session.currentUser?.workerProfile?.myOpenWorkItems?.map((item) => (
              <WorkItemListItem key={item.id} workItem={item} />
            ))}

          <IonItemDivider
            hidden={
              (session.currentUser?.workerProfile?.participatingOpenWorkItems
                ?.length ?? 0) == 0
            }
          >
            <IonLabel>
              <h2>Participating conversations:</h2>
            </IonLabel>
          </IonItemDivider>
          {session.currentUser?.workerProfile?.openWorkItems?.loaded &&
            session.currentUser?.workerProfile?.participatingOpenWorkItems?.map(
              (item) => <WorkItemListItem key={item.id} workItem={item} />
            )}
        </IonList>

        {(segment === "closedConversations" ||
          closedConversationsInitalized) && (
          <IonList hidden={segment !== "closedConversations"}>
            {session.currentUser?.workerProfile?.closedWorkItems?.loaded &&
              session.currentUser?.workerProfile?.closedWorkItems?.data?.map(
                (item) => <WorkItemListItem key={item.id} workItem={item} />
              )}
          </IonList>
        )}
      </IonContent>

      <IonFooter hidden>
        <IonList lines="none">
          <IonItemDivider>
            <IonLabel>
              <h2>New</h2>
            </IonLabel>
          </IonItemDivider>

          {session.currentUser?.workerProfile?.availableConversations?.loaded &&
            session.currentUser?.workerProfile?.availableConversations?.data?.map(
              (item) => (
                <ConversationListItem key={item.id} conversation={item} />
              )
            )}
        </IonList>
      </IonFooter>
    </IonPage>
  );
});

export default ConversationsPage;
