import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";
import { FirestoreDocumentData } from "./common";

export interface ConversationOutcomeData extends FirestoreDocumentData {
  category: string | null;
  name: string | null;
  text: string | null;
}

const __cache: Record<string, ConversationOutcome> = {};

export class ConversationOutcome {
  mPath: string;
  mData: ConversationOutcomeData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string, data?: ConversationOutcomeData) {
    makeAutoObservable(this);

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;
  }

  static createOrUpdateInstance(path: string, data?: ConversationOutcomeData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new ConversationOutcome(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: ConversationOutcomeData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(conversationOutcomeDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(conversationOutcomeDataConverter)
        .onSnapshot(
          action(
            (snapshot: firebase.firestore.DocumentSnapshot<ConversationOutcomeData>) => {
              const data = snapshot.data();
              this.mData = data;
            }
          )
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get category(): string | null | undefined {
    if (this.data) return this.data.category;
    return undefined;
  }

  get name(): string | null | undefined {
    if (this.data) return this.data.name;
    return undefined;
  }

  get text(): string | null | undefined {
    if (this.data) return this.data.text;
    return undefined;
  }

}

export const conversationOutcomeDataConverter = {
  toFirestore: function (data: ConversationOutcomeData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationOutcomeData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      category: data.category ?? null,
      name: data.name ?? null,
      text: data.text ?? null,
    } as ConversationOutcomeData;
  },
};

export const conversationOutcomeConverter = {
  toFirestore: function (conversationOutcome: ConversationOutcome) {
    return conversationOutcome.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationOutcomeData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return ConversationOutcome.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      category: data.category ?? null,
      name: data.name ?? null,
      text: data.text ?? null,
    });
  },
};

export class ConversationOutcomes {
  mCollectionRef: firebase.firestore.CollectionReference<ConversationOutcome>;
  mQuery: firebase.firestore.Query<ConversationOutcome> | null;
  mPath: string;
  mData: ConversationOutcome[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(conversationOutcomeConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<ConversationOutcome> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<ConversationOutcome>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get().then(
        action((snapshot: firebase.firestore.QuerySnapshot<ConversationOutcome>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<ConversationOutcome>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
