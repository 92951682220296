import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonModal,
  IonItem,
  IonProgressBar,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonItemDivider,
  IonList
} from "@ionic/react";
import {
  close,
} from "ionicons/icons";
import { Conversation } from "../models/Conversations";
import session from "../session";
import { ConversationOutcome } from "../models/ConversationOutcomes";
import { observer } from "mobx-react-lite";

interface CloseConversationButtonProps {
  parent?: HTMLElement | null;
  conversation: Conversation | null | undefined;
}

const CloseConversationButton: React.FC<CloseConversationButtonProps> = observer(({ parent, conversation }) => {
  const [showModal, set_showModal] = useState(false);

  const outcomes = session.conversationOutcomes?.data?.reduce(
    (groups: Record<string, ConversationOutcome[]>, item) => {
      if (item.category) {
        const groupName = item.category;

        if (!groups[groupName]) {
          groups[groupName] = [];
        }

        groups[groupName].push(item);
      }

      return groups;
    },
    {}
  );

  return (
    <>
      <IonButton
        shape="round"
        expand="block"
        onClick={() => set_showModal(true)}
        disabled={conversation === undefined || conversation === null}
        hidden={!(conversation?.status == 'active' && conversation.ownerId == session.currentUser?.workerProfileId)}
      >
        End
      </IonButton>

      <IonModal animated isOpen={showModal} swipeToClose>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="end">
              <IonButton onClick={() => set_showModal(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>

            <IonTitle>Conversation Outcome</IonTitle>

            <IonButtons slot="end">
              {/*<IonButton onClick={onDismissModal} strong>Done</IonButton>*/}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList lines="none">
          {outcomes &&
            Object.keys(outcomes).map((key) => (
              <>
                <IonItemDivider sticky>{key}</IonItemDivider>
                {outcomes[key].map((item) => (
                  <IonItem button key={item.id} onClick={() => {
                        conversation?.closeConversation(item).then(() => set_showModal(false))
                      }
                    }>
                    <IonLabel>
                      {item.name}
                    </IonLabel>
                  </IonItem>
                ))}
              </>
            ))
          }
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
});

export default CloseConversationButton;