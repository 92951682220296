import React from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonProgressBar,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import Menu from "./components/Menu";

import {
  peopleOutline,
  personOutline,
  people,
  person,
  chatbubbles,
  cog,
} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import session from "./session";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import AuthCheck from "./utils/AuthCheck";
import { observer } from "mobx-react-lite";
import ConversationsPage from "./pages/ConversationsPage";
import SettingsPage from "./pages/SettingsPage";
import CustomersPage from "./pages/CustomersPage";
import ConversationPage from "./pages/ConversationPage";
import CustomerPage from "./pages/CustomerPage";
import MainTabs from "./pages/Main";

const App: React.FC = observer(() => {
  return (
    <>
      {session.loading && <IonProgressBar type="indeterminate" />}
      {session.loaded && (
        <IonApp>
          <IonReactRouter>
            <Route exact path="/login" render={() => <LoginPage />} />
            <Route exact path="/signup" render={() => <SignupPage />} />

            <AuthCheck fallback={<LoginPage />}>
              <MainTabs />
            </AuthCheck>
          </IonReactRouter>
        </IonApp>
      )}
    </>
  );
});

export default App;
