import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";
import { FirestoreDocumentData } from "./common";

export interface CustomerData extends FirestoreDocumentData {
  firstName: string | null;
  lastName: string | null;
  mobileNumber: string | null;
}

const __cache: Record<string, Customer> = {};

export class Customer {
  mPath: string;
  mData: CustomerData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string, data?: CustomerData) {
    makeAutoObservable(this);

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;
  }

  static createOrUpdateInstance(path: string, data?: CustomerData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new Customer(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: CustomerData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(customerDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(customerDataConverter)
        .onSnapshot(
          action(
            (snapshot: firebase.firestore.DocumentSnapshot<CustomerData>) => {
              const data = snapshot.data();
              this.mData = data;
            }
          )
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get firstName(): string | null | undefined {
    if (this.data) return this.data.firstName;
    return undefined;
  }

  get lastName(): string | null | undefined {
    if (this.data) return this.data.lastName;
    return undefined;
  }

  get mobileNumber(): string | null | undefined {
    if (this.data) return this.data.mobileNumber;
    return undefined;
  }

  get fullName(): string | null | undefined {
    if (this.data) {
      const tempValue = (
        (this.firstName ?? "") +
        " " +
        (this.lastName ?? "")
      ).trim();
      return tempValue === "" ? null : tempValue;
    }
    return undefined;
  }
}

export const customerDataConverter = {
  toFirestore: function (data: CustomerData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<CustomerData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      firstName: data.firstName ?? null,
      lastName: data.lastName ?? null,
      mobileNumber: data.mobileNumber ?? null,
    } as CustomerData;
  },
};

export const customerConverter = {
  toFirestore: function (customer: Customer) {
    return customer.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<CustomerData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return Customer.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      firstName: data.firstName ?? null,
      lastName: data.lastName ?? null,
      mobileNumber: data.mobileNumber ?? null,
    });
  },
};

export class Customers {
  mCollectionRef: firebase.firestore.CollectionReference<Customer>;
  mQuery: firebase.firestore.Query<Customer> | null;
  mPath: string;
  mData: Customer[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(customerConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<Customer> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<Customer>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get().then(
        action((snapshot: firebase.firestore.QuerySnapshot<Customer>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<Customer>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
