import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import {
  IonLabel,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  CreateAnimation,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { closeOutline, logoGoogle, logoFacebook } from "ionicons/icons";
import { useForm, Controller } from "react-hook-form";
import session from "../session";
import { observer } from "mobx-react-lite";

const debug = false;

const SignUpPage: React.FC = observer(() => {
  const history = useHistory();

  if (debug) console.log("Component: Render - UserSignUp");

  const { control, handleSubmit, formState, reset, errors } = useForm({
    defaultValues: {
      rangeInfo: -100,
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <IonLabel color="primary">
        <h5>{error.message || "Field Is Required"}</h5>
      </IonLabel>
    ) : null;
  };

  const onSubmit = (data: any) => {
    if (formState.isValid) {
      return session
        .signUp(data.email, data.password, data.firstName, data.lastName)
        .then(() =>
          session
            .signOut()
            .then(() =>
              session
                .signInWithEmailAndPassword(data.email, data.password)
                .then(() => history.push("/"))
            )
        );
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="end">
            <IonButton routerLink="/login" routerDirection="back">
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Create your account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 18 }}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <Controller
                    control={control}
                    name="firstName"
                    rules={{
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="First Name"
                      />
                    )}
                  />
                  {showError("firstName")}
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem lines="none">
                  <Controller
                    control={control}
                    name="lastName"
                    rules={{
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must be at least 2 characters long",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Last Name"
                      />
                    )}
                  />
                  {showError("lastName")}
                </IonItem>
              </IonCol>
            </IonRow>
            {/*<IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <Controller
                      control={control}
                      name="company"
                      rules={{
                        required: true,
                        minLength: {
                          value: 2,
                          message: "Must be at least 2 characters long",
                        },
                      }}
                      render={({ onBlur, onChange, value }) => (
                        <IonInput
                          onIonInput={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Company"
                        />
                      )}
                    />
                    {showError("company")}
                  </IonItem>
                </IonCol>
                      </IonRow>*/}
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        type="email"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Email"
                      />
                    )}
                  />
                  {showError("email")}
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem lines="none">
                  <Controller
                    control={control}
                    name="password"
                    rules={{
                      required: true,
                      minLength: {
                        value: 6,
                        message: "Must be at least 6 characters long",
                      },
                    }}
                    render={({ onBlur, onChange, value }) => (
                      <IonInput
                        type="password"
                        onIonInput={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder="Password"
                      />
                    )}
                  />
                  {showError("password")}
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  type="submit"
                  disabled={!formState.isValid || formState.isSubmitting}
                  expand="block"
                >
                  {formState.isSubmitting
                    ? "Creating account..."
                    : "Create account"}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow style={{ marginTop: "20px" }}>
              <IonCol>
                <IonLabel>
                  <p className="ion-text-center">Or sign up with</p>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  shape="round"
                  color="light"
                  expand="block"
                  routerLink="/signup"
                  routerDirection="none"
                >
                  <IonIcon icon={logoGoogle} slot="start"></IonIcon>
                  Google
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  shape="round"
                  color="light"
                  expand="block"
                  routerLink="/signup"
                  routerDirection="none"
                >
                  <IonIcon icon={logoFacebook} slot="start"></IonIcon>
                  Facebook
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
});

export default SignUpPage;
