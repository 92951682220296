import {
  makeObservable,
  observable,
  action,
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { User } from "./models/Users";
import { Customers } from "./models/Customers";
import { ConversationOutcomes } from "./models/ConversationOutcomes";

const firebaseConfig = {
  apiKey: "AIzaSyCNqSK33MA7p3TOrHFyYUCdlQ_-VQPxciI",
  authDomain: "poised-tenure-266920.firebaseapp.com",
  databaseURL: "https://poised-tenure-266920.firebaseio.com",
  projectId: "poised-tenure-266920",
  storageBucket: "poised-tenure-266920.appspot.com",
  messagingSenderId: "1038260473214",
  appId: "1:1038260473214:web:a6fc4ffcc9bc92852e772f",
};

firebase.initializeApp(firebaseConfig);

export class Session {
  mCurrentUserId: string | null | undefined;
  mCurrentUser: User | null | undefined;
  mCustomers: Customers | null | undefined;
  mConversationOutcomes: ConversationOutcomes | null | undefined;

  constructor() {
    makeAutoObservable(this);

    onBecomeObserved(this, "currentUser", this.subscribeCurrentUser);
    onBecomeUnobserved(this, "currentUser", this.subscribeCurrentUser);
    onBecomeObserved(this, "customers", this.subscribeCustomers);
    onBecomeUnobserved(this, "customers", this.subscribeCustomers);
    onBecomeObserved(this, "conversationOutcomes", this.subscribeConversationOutcomes);
    onBecomeUnobserved(this, "conversationOutcomes", this.subscribeConversationOutcomes);

    firebase.auth().onAuthStateChanged(
      action((user: firebase.User | null) => {
        this.mCurrentUserId = user ? user.uid : null;
      })
    );

    /*firebase.auth()
          .getRedirectResult()
          .then(action((result: any) => {
            if (result.credential) {
              this.mCurrentUserId = (result.user ? result.user.uid : null);
            }
        }));*/
  }

  get loading() {
    return this.currentUserId === undefined;
  }

  get loaded() {
    return this.currentUserId !== undefined;
  }

  get signedIn() {
    return this.loaded && this.currentUserId !== null;
  }

  get currentUserId() {
    return this.mCurrentUserId;
  }

  get currentUser() {
    return this.mCurrentUser;
  }
  subscribeCurrentUser = async () => {
    await when(() => this.currentUserId !== undefined);
    if (this.mCurrentUser === undefined && this.currentUserId !== undefined) {
      runInAction(() => {
        this.mCurrentUser = this.currentUserId
          ? User.createOrUpdateInstance(`userProfiles/${this.currentUserId}`)
          : null;
        this.mCurrentUser?.subscribe();
      });
    }
  };
  unsubscribeCurrentUser = () => {
    this.mCurrentUser?.unsubscribe();
    this.mCurrentUser = undefined;
  };

  get customers(): Customers | null | undefined {
    return this.mCustomers;
  }
  subscribeCustomers = async () => {
    if (this.mCustomers === undefined) {
      runInAction(() => {
        this.mCustomers = new Customers(`customers`);

        let query = this.mCustomers.collectionRef.orderBy("firstName");
        this.mCustomers.query = query;

        this.mCustomers?.subscribe();
      });
    }
  };
  unsubscribeCustomers = () => {
    this.mCustomers?.unsubscribe();
    this.mCustomers = undefined;
  };

  get conversationOutcomes(): ConversationOutcomes | null | undefined {
    return this.mConversationOutcomes;
  }
  subscribeConversationOutcomes = async () => {
    if (this.mConversationOutcomes === undefined) {
      runInAction(() => {
        this.mConversationOutcomes = new ConversationOutcomes(`templates/conversationOutcome/templateOptions`);

        let query = this.mConversationOutcomes.collectionRef.orderBy("name");
        this.mConversationOutcomes.query = query;

        this.mConversationOutcomes?.subscribe();
      });
    }
  };
  unsubscribeConversationOutcomes = () => {
    this.mConversationOutcomes?.unsubscribe();
    this.mConversationOutcomes = undefined;
  };

  signInWithEmailAndPassword(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return (
      firebase
        .auth()
        //.signInWithRedirect(provider)
        .signInWithPopup(provider)
        .catch((error) => alert(error.message || error))
    );
  }

  signOut() {
    return firebase.auth().signOut();
  }

  signUp(email: string, password: string, firstName: string, lastName: string) {
    return new Promise((resolve, reject) =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          session
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              firebase
                .firestore()
                .collection("users")
                .doc(userCredential.user!.uid)
                .set({
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                })
                .then(() => resolve(userCredential.user!.uid))
                .catch((reason) => reject(reason));
            })
            .catch((reason) => reject(reason));
        })
        .catch((reason) => {
          reject(reason);
        })
    );
  }
}

const session = new Session();
export default session;
