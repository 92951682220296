import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";
import { FirestoreDocumentData } from "./common";
import { WorkerProfile } from "./WorkerProfiles";

export interface UserData extends FirestoreDocumentData {
  firstName: string | null;
  lastName: string | null;
  activeWorkerProfileId: string | null;
}

const __cache: Record<string, User> = {};

export class User {
  mPath: string;
  mData: UserData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;
  mWorkerProfile: WorkerProfile | null | undefined;

  constructor(path: string, data?: UserData) {
    makeAutoObservable(this);

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "workerProfile", this.subscribeWorkerProfile);
    onBecomeUnobserved(this, "workerProfile", this.unsubscribeWorkerProfile);
  }

  static createOrUpdateInstance(path: string, data?: UserData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new User(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: UserData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(userDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(userDataConverter)
        .onSnapshot(
          action((snapshot: firebase.firestore.DocumentSnapshot<UserData>) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get firstName(): string | null | undefined {
    if (this.data) return this.data.firstName;
    return undefined;
  }

  get lastName(): string | null | undefined {
    if (this.data) return this.data.lastName;
    return undefined;
  }

  get workerProfileId(): string | null | undefined {
    if (this.data) return this.data.activeWorkerProfileId;
    return undefined;
  }

  get workerProfile(): WorkerProfile | null | undefined {
    return this.mWorkerProfile;
  }
  subscribeWorkerProfile = () => {
    when(
      () => this.workerProfileId !== undefined,
      () => {
        if (
          this.mWorkerProfile === undefined &&
          this.workerProfileId !== undefined
        ) {
          runInAction(() => {
            this.mWorkerProfile = this.workerProfileId
              ? WorkerProfile.createOrUpdateInstance(
                  `workerProfiles/${this.workerProfileId}`
                )
              : null;
            this.mWorkerProfile?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeWorkerProfile = () => {
    this.mWorkerProfile?.unsubscribe();
    this.mWorkerProfile = undefined;
  };
}

export const userDataConverter = {
  toFirestore: function (data: UserData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<UserData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      firstName: data.firstName ?? null,
      lastName: data.lastName ?? null,
      activeWorkerProfileId: data.activeWorkerProfileId ?? null,
    } as UserData;
  },
};

export const userConverter = {
  toFirestore: function (user: User) {
    return user.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<UserData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return User.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      firstName: data.firstName ?? null,
      lastName: data.lastName ?? null,
      activeWorkerProfileId: data.activeWorkerProfileId ?? null,
    });
  },
};

export class Users {
  mCollectionRef: firebase.firestore.CollectionReference<User>;
  mQuery: firebase.firestore.Query<User> | null;
  mPath: string;
  mData: User[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(userConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<User> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<User>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.mQuery ?? this.collectionRef).get().then(
        action((snapshot: firebase.firestore.QuerySnapshot<User>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<User>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
