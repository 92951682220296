import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";
import { FirestoreDocumentData } from "./common";
import { Conversation } from "./Conversations";
import { User } from "./Users";
import { WorkerProfile } from "./WorkerProfiles";

export interface WorkItemData extends FirestoreDocumentData {
  hasUnreadInternalMessages: boolean | null;
  hasUnreadNotes: boolean | null;
  unreadMessages: boolean | null;
  lastContactDate: firebase.firestore.Timestamp | null;
  lastInternalMessageReadAt: firebase.firestore.Timestamp | null;
  lastInternalMessageReceivedAt: firebase.firestore.Timestamp | null;
  lastInternalMessageSenderId: string | null;
  lastMessageDirection: "in" | "out" | null;
  lastMessageReadAt: firebase.firestore.Timestamp | null;
  lastMessageReadOn: firebase.firestore.Timestamp | null;
  lastNoteReadAt: firebase.firestore.Timestamp | null;
  lastNoteSenderId: string | null;
  conversationId: string | null;
}

const __cache: Record<string, WorkItem> = {};

export class WorkItem {
  mPath: string;
  mData: WorkItemData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;
  mLastInternalMessageSender: WorkerProfile | null | undefined;
  mLastNoteSender: WorkerProfile | null | undefined;
  mConversation: Conversation | null | undefined;

  constructor(path: string, data?: WorkItemData) {
    makeAutoObservable(this);

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;

    onBecomeObserved(
      this,
      "lastInternalMessageSender",
      this.subscribeLastInternalMessageSender
    );
    onBecomeUnobserved(
      this,
      "lastInternalMessageSender",
      this.unsubscribeLastInternalMessageSender
    );
    onBecomeObserved(this, "lastNoteSender", this.subscribeLastNoteSender);
    onBecomeUnobserved(this, "lastNoteSender", this.unsubscribeLastNoteSender);
    onBecomeObserved(this, "conversation", this.subscribeConversation);
    onBecomeUnobserved(this, "conversation", this.unsubscribeConversation);
  }

  static createOrUpdateInstance(path: string, data?: WorkItemData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new WorkItem(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: WorkItemData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(workItemDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(workItemDataConverter)
        .onSnapshot(
          action(
            (snapshot: firebase.firestore.DocumentSnapshot<WorkItemData>) => {
              const data = snapshot.data();
              this.mData = data;
            }
          )
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get hasUnreadInternalMessages(): boolean | null | undefined {
    if (this.data) return this.data.hasUnreadInternalMessages;
    return undefined;
  }

  get hasUnreadNotes(): boolean | null | undefined {
    if (this.data) return this.data.hasUnreadNotes;
    return undefined;
  }

  get unreadMessages(): boolean | null | undefined {
    if (this.data) return this.data.unreadMessages;
    return undefined;
  }

  get lastContactDate(): Date | null | undefined {
    if (this.data) return this.data.lastContactDate?.toDate();
    return undefined;
  }
  get lastInternalMessageReadAt(): Date | null | undefined {
    if (this.data) return this.data.lastInternalMessageReadAt?.toDate();
    return undefined;
  }
  get lastInternalMessageReceivedAt(): Date | null | undefined {
    if (this.data) return this.data.lastInternalMessageReceivedAt?.toDate();
    return undefined;
  }
  get lastMessageDirection(): "in" | "out" | null | undefined {
    if (this.data) return this.data.lastMessageDirection;
    return undefined;
  }
  get lastMessageReadAt(): Date | null | undefined {
    if (this.data) return this.data.lastMessageReadAt?.toDate();
    return undefined;
  }
  get lastMessageReadOn(): Date | null | undefined {
    if (this.data) return this.data.lastMessageReadOn?.toDate();
    return undefined;
  }
  get lastNoteReadAt(): Date | null | undefined {
    if (this.data) return this.data.lastNoteReadAt?.toDate();
    return undefined;
  }

  get lastInternalMessageSenderId(): string | null | undefined {
    if (this.data) return this.data.lastInternalMessageSenderId;
    return undefined;
  }

  get lastInternalMessageSender(): WorkerProfile | null | undefined {
    return this.mLastInternalMessageSender;
  }
  subscribeLastInternalMessageSender = () => {
    when(
      () => this.lastInternalMessageSenderId !== undefined,
      () => {
        if (
          this.mLastInternalMessageSender === undefined &&
          this.lastInternalMessageSenderId !== undefined
        ) {
          runInAction(() => {
            this.mLastInternalMessageSender = this.lastInternalMessageSenderId
              ? WorkerProfile.createOrUpdateInstance(
                  `workerProfiles/${this.lastInternalMessageSenderId}`
                )
              : null;
            this.mLastInternalMessageSender?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeLastInternalMessageSender = () => {
    this.mLastInternalMessageSender?.unsubscribe();
    this.mLastInternalMessageSender = undefined;
  };

  get lastNoteSenderId(): string | null | undefined {
    if (this.data) return this.data.lastNoteSenderId;
    return undefined;
  }

  get lastNoteSender(): WorkerProfile | null | undefined {
    return this.mLastNoteSender;
  }
  subscribeLastNoteSender = async () => {
    when(
      () => this.lastNoteSenderId !== undefined,
      () => {
        if (
          this.mLastNoteSender === undefined &&
          this.lastNoteSenderId !== undefined
        ) {
          runInAction(() => {
            this.mLastNoteSender = this.lastNoteSenderId
              ? WorkerProfile.createOrUpdateInstance(
                  `workerProfiles/${this.lastNoteSenderId}`
                )
              : null;
            this.mLastNoteSender?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeLastNoteSender = () => {
    this.mLastNoteSender?.unsubscribe();
    this.mLastNoteSender = undefined;
  };

  get conversationId(): string | null | undefined {
    if (this.data) return this.data.conversationId;
    return undefined;
  }

  get conversation(): Conversation | null | undefined {
    return this.mConversation;
  }
  subscribeConversation = () => {
    when(
      () => this.conversationId !== undefined,
      () => {
        if (
          this.mConversation === undefined &&
          this.conversationId !== undefined
        ) {
          runInAction(() => {
            this.mConversation = this.conversationId
              ? Conversation.createOrUpdateInstance(
                  `conversations/${this.conversationId}`
                )
              : null;
            this.mConversation?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeConversation = () => {
    this.mConversation?.unsubscribe();
    this.mConversation = undefined;
  };
}

export const workItemDataConverter = {
  toFirestore: function (data: WorkItemData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<WorkItemData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      hasUnreadInternalMessages: data.hasUnreadInternalMessages ?? false,
      hasUnreadNotes: data.hasUnreadNotes ?? false,
      unreadMessages: data.unreadMessages ?? false,
      lastContactDate: data.lastContactDate ?? null,
      lastInternalMessageReadAt: data.lastInternalMessageReadAt ?? null,
      lastInternalMessageReceivedAt: data.lastInternalMessageReceivedAt ?? null,
      lastInternalMessageSenderId: data.lastInternalMessageSenderId ?? null,
      lastMessageDirection: data.lastMessageDirection ?? "in",
      lastMessageReadAt: data.lastMessageReadAt ?? null,
      lastMessageReadOn: data.lastMessageReadOn ?? null,
      lastNoteReadAt: data.lastNoteReadAt ?? null,
      lastNoteSenderId: data.lastNoteSenderId ?? null,
      conversationId: snapshot.id ?? null,
    } as WorkItemData;
  },
};

export const workItemConverter = {
  toFirestore: function (workItem: WorkItem) {
    return workItem.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<WorkItemData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return WorkItem.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      hasUnreadInternalMessages: data.hasUnreadInternalMessages ?? false,
      hasUnreadNotes: data.hasUnreadNotes ?? false,
      unreadMessages: data.unreadMessages ?? false,
      lastContactDate: data.lastContactDate ?? null,
      lastInternalMessageReadAt: data.lastInternalMessageReadAt ?? null,
      lastInternalMessageReceivedAt: data.lastInternalMessageReceivedAt ?? null,
      lastInternalMessageSenderId: data.lastInternalMessageSenderId ?? null,
      lastMessageDirection: data.lastMessageDirection ?? "in",
      lastMessageReadAt: data.lastMessageReadAt ?? null,
      lastMessageReadOn: data.lastMessageReadOn ?? null,
      lastNoteReadAt: data.lastNoteReadAt ?? null,
      lastNoteSenderId: data.lastNoteSenderId ?? null,
      conversationId: snapshot.id ?? null,
    });
  },
};

export class WorkItems {
  mCollectionRef: firebase.firestore.CollectionReference<WorkItem>;
  mQuery: firebase.firestore.Query<WorkItem> | null;
  mPath: string;
  mData: WorkItem[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(workItemConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<WorkItem> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<WorkItem>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get().then(
        action((snapshot: firebase.firestore.QuerySnapshot<WorkItem>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<WorkItem>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
