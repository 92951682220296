import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
  trace,
} from "mobx";
import { isThisTypeNode } from "typescript";
import session from "../session";
import { FirestoreDocumentData } from "./common";
import { ConversationOutcome } from "./ConversationOutcomes";
import { Customer } from "./Customers";
import { InternalMessage, InternalMessages } from "./InternalMessages";
import { Message, messageConverter, Messages } from "./Messages";
import { WorkerProfile } from "./WorkerProfiles";

export interface ConversationData extends FirestoreDocumentData {
  customerId: string | null;
  status: string | null;
  ownerId: string | null;
}

const __cache: Record<string, Conversation> = {};

export class Conversation {
  mPath: string;
  mData: ConversationData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;
  mCustomer: Customer | null | undefined;
  mOwner: WorkerProfile | null | undefined;
  mMessages: Messages | null | undefined;
  mInternalMessages: InternalMessages | null | undefined;
  mLastMessage: Message | null | undefined;
  mLastMessageSubscription: any;
  mLastMessageSubscriptionCount: number;

  constructor(path: string, data?: ConversationData) {
    makeAutoObservable(this, {
      subscribeCustomer: false,
      unsubscribeCustomer: false,
      subscribeOwner: false,
      unsubscribeOwner: false,
      subscribeMessages: false,
      unsubscribeMessages: false,
      subscribeLastMessage: false,
      unsubscribeLastMessage: false,
      //mLastMessage: false,
      mLastMessageSubscription: false,
      mLastMessageSubscriptionCount: false,
    });

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;
    this.mLastMessageSubscriptionCount = 0;

    onBecomeObserved(this, "customer", this.subscribeCustomer);
    onBecomeUnobserved(this, "customer", this.unsubscribeCustomer);
    onBecomeObserved(this, "owner", this.subscribeOwner);
    onBecomeUnobserved(this, "owner", this.unsubscribeOwner);
    onBecomeObserved(this, "messages", this.subscribeMessages);
    onBecomeUnobserved(this, "messages", this.unsubscribeMessages);
    onBecomeObserved(this, "internalMessages", this.subscribeInternalMessages);
    onBecomeUnobserved(
      this,
      "internalMessages",
      this.unsubscribeInternalMessages
    );
    onBecomeObserved(this, "lastMessage", this.subscribeLastMessage);
    onBecomeUnobserved(this, "lastMessage", this.unsubscribeLastMessage);
  }

  static createOrUpdateInstance(path: string, data?: ConversationData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new Conversation(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: ConversationData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(conversationDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(conversationDataConverter)
        .onSnapshot(
          action(
            (
              snapshot: firebase.firestore.DocumentSnapshot<ConversationData>
            ) => {
              const data = snapshot.data();
              this.mData = data;
            }
          )
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get status(): string | null | undefined {
    if (this.data) return this.data.status;
    return undefined;
  }

  get customerId(): string | null | undefined {
    if (this.data) return this.data.customerId;
    return undefined;
  }

  get customer(): Customer | null | undefined {
    return this.mCustomer;
  }
  subscribeCustomer = () => {
    when(
      () => this.customerId !== undefined,
      () => {
        if (this.mCustomer === undefined && this.customerId !== undefined) {
          runInAction(() => {
            this.mCustomer = this.customerId
              ? Customer.createOrUpdateInstance(`customers/${this.customerId}`)
              : null;
            this.mCustomer?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeCustomer = () => {
    this.mCustomer?.unsubscribe();
    this.mCustomer = undefined;
  };

  get ownerId(): string | null | undefined {
    if (this.data) return this.data.ownerId;
    return undefined;
  }

  get owner(): WorkerProfile | null | undefined {
    return this.mOwner;
  }
  subscribeOwner = () => {
    when(
      () => this.ownerId !== undefined,
      () => {
        if (this.mOwner === undefined && this.ownerId !== undefined) {
          runInAction(() => {
            this.mOwner = this.ownerId
              ? WorkerProfile.createOrUpdateInstance(
                  `workerProfiles/${this.ownerId}`
                )
              : null;
            this.mOwner?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeOwner = () => {
    this.mOwner?.unsubscribe();
    this.mOwner = undefined;
  };

  get messages(): Messages | null | undefined {
    return this.mMessages;
  }
  subscribeMessages = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mMessages === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mMessages = this.id
              ? new Messages(`conversations/${this.id}/messages`)
              : null;
            if (this.mMessages) {
              let query = this.mMessages.collectionRef.orderBy("createdOn");
              this.mMessages.query = query;
            }
            this.mMessages?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeMessages = () => {
    this.mMessages?.unsubscribe();
    this.mMessages = undefined;
  };

  get internalMessages(): InternalMessages | null | undefined {
    return this.mInternalMessages;
  }
  subscribeInternalMessages = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mInternalMessages === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mInternalMessages = new InternalMessages(
              `conversations/${this.id}/internalMessages`
            );

            let query = this.mInternalMessages.collectionRef.orderBy(
              "createdOn"
            );
            this.mInternalMessages.query = query;

            this.mInternalMessages?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeInternalMessages = () => {
    this.mInternalMessages?.unsubscribe();
    this.mInternalMessages = undefined;
  };

  get lastMessage(): Message | null | undefined {
    return this.mLastMessage;
  }
  subscribeLastMessage = () => {
    when(
      () => this.id !== undefined,
      () => {
        ++this.mLastMessageSubscriptionCount;
        if (this.mLastMessage === undefined && this.id !== undefined) {
          console.log(
            `subscribe to conversations/${this.id}/messages/lastMessage`
          );
          this.mLastMessageSubscription = firebase
            .firestore()
            .collection(`conversations/${this.id}/messages`)
            .limit(1)
            .where("type", "!=", "system")
            .orderBy("type")
            .orderBy("createdOn", "desc")
            .withConverter(messageConverter)
            .onSnapshot(
              action((snapshot: firebase.firestore.QuerySnapshot<Message>) => {
                const data = snapshot.docs.map((doc) => doc.data());
                this.mLastMessage = data[0] ?? null;
              })
            );
        }
      }
    );
  };
  unsubscribeLastMessage = () => {
    if (--this.mLastMessageSubscriptionCount < 0) {
      this.mLastMessageSubscriptionCount = 0;
    }

    if (
      this.mLastMessageSubscription &&
      this.mLastMessageSubscriptionCount == 0
    ) {
      this.mLastMessageSubscription();
      this.mLastMessage = undefined;
    }
  };

  closeConversation(outcome: ConversationOutcome) {
      return new Promise<void>((resolve, reject) => {
      firebase
          .firestore()
          .doc(this.mPath)
          .update({
            outcome: outcome.data,
            status: 'inactive'
          })
          .then(() => resolve(undefined))
    });
  }

  sendMessage(messageBody: string) {
    return new Promise<void>((resolve, reject) => {
      when(
        () => this.lastMessage?.loaded ?? false,
        () => {
          console.log(this.lastMessage?.body);

          const envelope = {
            provider: {
              name: this.lastMessage?.communicationChannel?.provider,
              channel: this.lastMessage?.communicationChannel?.channel,
            },
            payload: {
              conversationId: this.id,
              body: messageBody,
              from: this.lastMessage?.communicationChannel?.mobileNumber,
              to: this.customer?.mobileNumber,
            },
          };

          //runInAction(() => {
          this.messages?.data?.push(
            new Message("", {
              id: "",
              body: messageBody,
              communicationChannel: {
                channel: this.lastMessage!.communicationChannel!.channel,
                mobileNumber: this.lastMessage!.communicationChannel!
                  .mobileNumber,
                provider: this.lastMessage!.communicationChannel!.provider,
              },
              createdOn: firebase.firestore.Timestamp.now(),
              customerId: this.customerId!,
              direction: "out",
              serviceProviderId: null,
              status: "pending",
              workerProfileId: session.currentUser!.workerProfileId!,
              type: "agent",
            })
          );
          //});

          const publishMessage = firebase
            .functions()
            .httpsCallable("publishMessage");
          publishMessage(envelope).then(() => resolve(undefined));
        }
      );
    });
  }

  sendInternalMessage(messageBody: string) {
    return new Promise<void>((resolve, reject) => {
      when(
        () => this.lastMessage?.loaded ?? false,
        () => {
          const internalMessage = {
            createdOn: firebase.firestore.FieldValue.serverTimestamp(),
            body: messageBody,
            senderId: session.currentUser!.workerProfileId!,
          };

          this.internalMessages?.data?.push(
            new InternalMessage("", {
              id: "",
              body: messageBody,
              createdOn: firebase.firestore.Timestamp.now(),
              status: "pending",
              senderId: session.currentUser!.workerProfileId!,
            })
          );

          firebase
            .firestore()
            .collection(`conversations/${this.id}/internalMessages`)
            .add(internalMessage)
            .then(() => resolve(undefined))
        }
      );
    });
  }
}

export const conversationDataConverter = {
  toFirestore: function (data: ConversationData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      customerId: data.customerId ?? null,
      status: data.status ?? null,
      ownerId: data.ownerId ?? null,
    } as ConversationData;
  },
};

export const conversationConverter = {
  toFirestore: function (conversation: Conversation) {
    return conversation.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<ConversationData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return Conversation.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      customerId: data.customerId ?? null,
      status: data.status ?? null,
      ownerId: data.ownerId ?? null,
    });
  },
};

export class Conversations {
  mCollectionRef: firebase.firestore.CollectionReference<Conversation>;
  mQuery: firebase.firestore.Query<Conversation> | null;
  mPath: string;
  mData: Conversation[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(conversationConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<Conversation> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<Conversation>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get().then(
        action((snapshot: firebase.firestore.QuerySnapshot<Conversation>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<Conversation>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
