import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonIcon,
  IonThumbnail,
  IonSkeletonText,
  IonBadge,
  IonRow,
  IonText,
} from "@ionic/react";

import {
  peopleOutline,
  personOutline,
  people,
  person,
  chatbubbles,
  cog,
  personCircle,
  timeOutline,
} from "ionicons/icons";
import moment from "moment";

import { observer } from "mobx-react-lite";
import { Message } from "../models/Messages";

import session from "../session";
import React from "react";
import { timeout } from "workbox-core/_private";

interface MessageListItemProps {
  message: Message;
}

const MessageListItem: React.FC<MessageListItemProps> = observer(
  ({ message }) => {
    if (
      message.loaded &&
      session.currentUser?.workerProfile?.loaded &&
      ((message.type === "agent" &&
        message.workerProfile &&
        message.workerProfile.loaded) ||
        message.type !== "agent")
    ) {
      const messageDate = moment(message.createdOn).format("HH:MM");
      const messageSender =
        message.type === "agent"
          ? message.workerProfile?.id === session.currentUser?.workerProfile?.id
            ? "You"
            : `${message.workerProfile?.name}`
          : message.type === "system"
          ? "System"
          : "";
      const messageBody = message.body;

      return (
        <IonRow
          className={`${
            message.direction === "out"
              ? "ion-justify-content-end ion-margin-start"
              : "ion-justify-content-start ion-margin-end"
          } ion-padding`}
        >
          <IonItem
            color={
              messageSender === "You"
                ? "primary"
                : message.type === "agent"
                ? "light"
                : message.type === "system"
                ? ""
                : "light"
            }
            lines="none"
            style={{
              border: "1px",
              borderRadius:
                "5px" /*borderColor: "light", borderStyle: "solid"*/,
            }}
          >
            <IonLabel
              className="ion-text-wrap"
              color={message.type === "system" ? "medium" : ""}
            >
              <div>
                {messageSender !== "You" && <b>{messageSender}</b>}
                <span className="ion-text-end ion-float-right"></span>
              </div>
              <div>
                <p>{messageBody}</p>
              </div>
              <div></div>
            </IonLabel>
            <IonNote slot="end" color={messageSender === 'You' ? "light" : "medium"}>
              <span className="ion-text-end ion-float-right ion-align-items-end">
                {message.status === "pending" ? (
                  <IonIcon icon={timeOutline} />
                ) : (
                  messageDate
                )}
              </span>
            </IonNote>
          </IonItem>
        </IonRow>
      );
    }

    return (
      <IonItem lines="full">
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "50%" }} slot="start" />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: "90%" }} />
          </p>
        </IonLabel>
      </IonItem>
    );
  }
);

export default MessageListItem;
