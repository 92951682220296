import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonIcon,
  IonThumbnail,
  IonSkeletonText,
  IonBadge,
  IonRow,
  IonText,
} from "@ionic/react";

import {
  peopleOutline,
  personOutline,
  people,
  person,
  chatbubbles,
  cog,
  personCircle,
  timeOutline,
} from "ionicons/icons";
import moment from "moment";

import { observer } from "mobx-react-lite";
import { Message } from "../models/Messages";

import session from "../session";
import React from "react";
import { timeout } from "workbox-core/_private";
import { InternalMessage } from "../models/InternalMessages";

interface InternalMessageListItemProps {
  internalMessage: InternalMessage;
}

const InternalMessageListItem: React.FC<InternalMessageListItemProps> = observer(
  ({ internalMessage }) => {
    if (
      internalMessage.loaded &&
      session.currentUser?.workerProfile?.loaded &&
      internalMessage.sender?.loaded
    ) {
      const messageDate = moment(internalMessage.createdOn).format("HH:MM");
      const messageSender =
        internalMessage.sender?.id === session.currentUser?.workerProfile?.id
          ? "You"
          : `${internalMessage.sender?.name}`;
      const messageBody = internalMessage.body;

      return (
        <IonRow
          className={`${
            messageSender === "You"
              ? "ion-justify-content-end ion-margin-start"
              : "ion-justify-content-start ion-margin-end"
          } ion-padding`}
        >
          <IonItem
            color={messageSender === "You" ? "primary" : "light"}
            lines="none"
            style={{
              border: "1px",
              borderRadius:
                "5px" /*borderColor: "light", borderStyle: "solid"*/,
            }}
          >
            <IonLabel className="ion-text-wrap">
              <div>{messageSender !== "You" && <b>{messageSender}</b>}</div>
              <div>
                <p>{messageBody}</p>
              </div>
            </IonLabel>
            <IonNote slot="end" color={messageSender === 'You' ? "light" : ""}>
              {internalMessage.status === "pending" ? (
                <IonIcon icon={timeOutline} />
              ) : (
                messageDate
              )}
            </IonNote>
          </IonItem>
        </IonRow>
      );
    }

    return (
      <IonItem lines="full">
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "50%" }} slot="start" />
          </h2>
          <p>
            <IonSkeletonText animated style={{ width: "90%" }} />
          </p>
        </IonLabel>
      </IonItem>
    );
  }
);

export default InternalMessageListItem;
