import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";
import { FirestoreDocumentData } from "./common";
import { WorkerProfile } from "./WorkerProfiles";
import { Customer } from "./Customers";

type CommunicationChannel = {
  channel: string | null;
  mobileNumber: string | null;
  provider: string | null;
};

export interface MessageData extends FirestoreDocumentData {
  body: string | null;
  communicationChannel: CommunicationChannel | null;
  createdOn: firebase.firestore.Timestamp | null;
  customerId: string | null;
  direction: "in" | "out" | null;
  serviceProviderId: string | null;
  status: string | null;
  workerProfileId: string | null;
  type: string | null;
}

const __cache: Record<string, Message> = {};

export class Message {
  mPath: string;
  mData: MessageData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;
  mCustomer: Customer | null | undefined;
  mWorkerProfile: WorkerProfile | null | undefined;

  constructor(path: string, data?: MessageData) {
    makeAutoObservable(this);

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "customer", this.subscribeCustomer);
    onBecomeUnobserved(this, "customer", this.unsubscribeCustomer);
    onBecomeObserved(this, "workerProfile", this.subscribeWorkerProfile);
    onBecomeUnobserved(this, "workerProfile", this.unsubscribeWorkerProfile);
  }

  static createOrUpdateInstance(path: string, data?: MessageData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new Message(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: MessageData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(messageDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(messageDataConverter)
        .onSnapshot(
          action(
            (snapshot: firebase.firestore.DocumentSnapshot<MessageData>) => {
              const data = snapshot.data();
              this.mData = data;
            }
          )
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get communicationChannel(): CommunicationChannel | null | undefined {
    if (this.data) return this.data.communicationChannel;
    return undefined;
  }

  get body(): string | null | undefined {
    if (this.data) return this.data.body;
    return undefined;
  }

  get createdOn(): Date | null | undefined {
    if (this.data) return this.data.createdOn?.toDate();
    return undefined;
  }

  get direction(): "in" | "out" | null | undefined {
    if (this.data) return this.data.direction;
    return undefined;
  }

  get status(): string | null | undefined {
    if (this.data) return this.data.status;
    return undefined;
  }

  get type(): string | null | undefined {
    if (this.data) return this.data.type;
    return undefined;
  }

  get customerId(): string | null | undefined {
    if (this.data) return this.data.customerId;
    return undefined;
  }

  get customer(): Customer | null | undefined {
    return this.mCustomer;
  }
  subscribeCustomer = () => {
    when(
      () => this.customerId !== undefined,
      () => {
        if (this.mCustomer === undefined && this.customerId !== undefined) {
          runInAction(() => {
            this.mCustomer = this.customerId
              ? Customer.createOrUpdateInstance(`customers/${this.customerId}`)
              : null;
            this.mCustomer?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeCustomer = () => {
    this.mCustomer?.unsubscribe();
    this.mCustomer = undefined;
  };

  get workerProfileId(): string | null | undefined {
    if (this.data) return this.data.workerProfileId;
    return undefined;
  }

  get workerProfile(): WorkerProfile | null | undefined {
    return this.mWorkerProfile;
  }
  subscribeWorkerProfile = () => {
    when(
      () => this.workerProfileId !== undefined,
      () => {
        if (
          this.mWorkerProfile === undefined &&
          this.workerProfileId !== undefined
        ) {
          runInAction(() => {
            this.mWorkerProfile = this.workerProfileId
              ? WorkerProfile.createOrUpdateInstance(
                  `workerProfiles/${this.workerProfileId}`
                )
              : null;
            this.mWorkerProfile?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeWorkerProfile = () => {
    this.mWorkerProfile?.unsubscribe();
    this.mWorkerProfile = undefined;
  };
}

export const messageDataConverter = {
  toFirestore: function (data: MessageData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<MessageData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      body: data.body ?? null,
      communicationChannel: data.communicationChannel ?? null,
      createdOn: data.createdOn ?? null,
      customerId: data.customerId ?? null,
      direction: data.direction ?? null,
      serviceProviderId: data.serviceProviderId ?? null,
      status: data.status ?? null,
      workerProfileId: data.workerProfileId ?? null,
      type: data.type ?? null,
    } as MessageData;
  },
};

export const messageConverter = {
  toFirestore: function (message: Message) {
    return message.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<MessageData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return Message.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      body: data.body ?? null,
      communicationChannel: data.communicationChannel ?? null,
      createdOn: data.createdOn ?? null,
      customerId: data.customerId ?? null,
      direction: data.direction ?? null,
      serviceProviderId: data.serviceProviderId ?? null,
      status: data.status ?? null,
      workerProfileId: data.workerProfileId ?? null,
      type: data.type ?? null,
    });
  },
};

export class Messages {
  mCollectionRef: firebase.firestore.CollectionReference<Message>;
  mQuery: firebase.firestore.Query<Message> | null;
  mPath: string;
  mData: Message[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(messageConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<Message> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<Message>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.query ?? this.collectionRef).get().then(
        action((snapshot: firebase.firestore.QuerySnapshot<Message>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.query ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<Message>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
