import firebase from "firebase/app";
import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  when,
  runInAction,
} from "mobx";
import { FirestoreDocumentData } from "./common";
import { Conversation, Conversations } from "./Conversations";
import { User } from "./Users";
import { WorkItem, WorkItems } from "./WorkItems";

export interface WorkerProfileData extends FirestoreDocumentData {
  name: string | null;
  userId: string | null;
}

const __cache: Record<string, WorkerProfile> = {};

export class WorkerProfile {
  mPath: string;
  mData: WorkerProfileData | undefined;
  mSubscription: any;
  mSubscriptionCount: number;
  mUser: User | null | undefined;
  mOpenWorkItems: WorkItems | null | undefined;
  mClosedWorkItems: WorkItems | null | undefined;
  mAvailableConversations: Conversations | null | undefined;

  constructor(path: string, data?: WorkerProfileData) {
    makeAutoObservable(this, {
      mSubscription: false,
      mSubscriptionCount: false,
      subscribeUser: false,
      unsubscribeUser: false,
      subscribeOpenWorkItems: false,
      unsubscribeOpenWorkItems: false,
      subscribeClosedWorkItems: false,
      unsubscribeClosedWorkItems: false,
      subscribeAvailableConversations: false,
      unsubscribeAvailableConversations: false,
    });

    this.mPath = path;
    this.mData = data;
    this.mSubscriptionCount = 0;

    onBecomeObserved(this, "user", this.subscribeUser);
    onBecomeUnobserved(this, "user", this.unsubscribeUser);
    onBecomeObserved(this, "openWorkItems", this.subscribeOpenWorkItems);
    onBecomeUnobserved(this, "openWorkItems", this.unsubscribeOpenWorkItems);
    onBecomeObserved(this, "closedWorkItems", this.subscribeClosedWorkItems);
    onBecomeUnobserved(
      this,
      "closedWorkItems",
      this.unsubscribeClosedWorkItems
    );
    onBecomeObserved(
      this,
      "availableConversations",
      this.subscribeAvailableConversations
    );
    onBecomeUnobserved(
      this,
      "availableConversations",
      this.unsubscribeAvailableConversations
    );
  }

  static createOrUpdateInstance(path: string, data?: WorkerProfileData) {
    let instance = __cache[path];
    if (!instance) {
      instance = new WorkerProfile(path, data);
      __cache[path] = instance;
    } else if (data !== undefined) {
      instance.data = data;
    }

    return instance;
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }
  set data(newValue: WorkerProfileData | undefined) {
    this.mData = newValue;
  }

  refetch() {
    if (!this.mSubscription) {
      firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(workerProfileDataConverter)
        .get()
        .then(
          action((snapshot) => {
            const data = snapshot.data();
            this.mData = data;
          })
        );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = firebase
        .firestore()
        .doc(this.mPath)
        .withConverter(workerProfileDataConverter)
        .onSnapshot(
          action(
            (
              snapshot: firebase.firestore.DocumentSnapshot<WorkerProfileData>
            ) => {
              const data = snapshot.data();
              this.mData = data;
            }
          )
        );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }

  get id(): string | undefined {
    if (this.data) return this.data.id;
    return undefined;
  }

  get name(): string | null | undefined {
    if (this.data) return this.data.name;
    return undefined;
  }

  get userId(): string | null | undefined {
    if (this.data) return this.data.userId;
    return undefined;
  }

  get user(): User | null | undefined {
    return this.mUser;
  }
  subscribeUser = () => {
    when(
      () => this.userId !== undefined,
      () => {
        if (this.mUser === undefined && this.userId !== undefined) {
          runInAction(() => {
            this.mUser = this.userId
              ? User.createOrUpdateInstance(`userProfiles/${this.userId}`)
              : null;
            this.mUser?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeUser = () => {
    this.mUser?.unsubscribe();
    this.mUser = undefined;
  };

  get openWorkItems(): WorkItems | null | undefined {
    return this.mOpenWorkItems;
  }
  subscribeOpenWorkItems = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mOpenWorkItems === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mOpenWorkItems = this.id
              ? new WorkItems(`workerProfiles/${this.id}/activeConversations`)
              : null;
            if (this.mOpenWorkItems) {
              let query = this.mOpenWorkItems.collectionRef.orderBy(
                "lastContactDate",
                "desc"
              );
              this.mOpenWorkItems.query = query;
            }
            this.mOpenWorkItems?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeOpenWorkItems = () => {
    this.mOpenWorkItems?.unsubscribe();
    this.mOpenWorkItems = undefined;
  };

  get myOpenWorkItems(): WorkItem[] | null | undefined {
    return this.openWorkItems?.data?.filter(
      (workItem) =>
        workItem.conversation?.loaded &&
        workItem.conversation?.ownerId === this.id
    );
  }

  get participatingOpenWorkItems(): WorkItem[] | null | undefined {
    return this.openWorkItems?.data?.filter(
      (workItem) =>
        workItem.conversation?.loaded &&
        workItem.conversation?.ownerId !== this.id
    );
  }

  get closedWorkItems(): WorkItems | null | undefined {
    return this.mClosedWorkItems;
  }
  subscribeClosedWorkItems = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (this.mClosedWorkItems === undefined && this.id !== undefined) {
          runInAction(() => {
            this.mClosedWorkItems = this.id
              ? new WorkItems(`workerProfiles/${this.id}/inactiveConversations`)
              : null;
            if (this.mClosedWorkItems) {
              let query = this.mClosedWorkItems.collectionRef.orderBy(
                "lastContactDate",
                "desc"
              );
              this.mClosedWorkItems.query = query;
            }
            this.mClosedWorkItems?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeClosedWorkItems = () => {
    this.mClosedWorkItems?.unsubscribe();
    this.mClosedWorkItems = undefined;
  };

  get availableConversations(): Conversations | null | undefined {
    return this.mAvailableConversations;
  }
  subscribeAvailableConversations = () => {
    when(
      () => this.id !== undefined,
      () => {
        if (
          this.mAvailableConversations === undefined &&
          this.id !== undefined
        ) {
          runInAction(() => {
            this.mAvailableConversations = new Conversations(`conversations`);

            let query = this.mAvailableConversations.collectionRef
              .where("ownerId", "==", null)
              .orderBy("createdOn", "asc")
              .limit(1);
            this.mAvailableConversations.query = query;

            this.mAvailableConversations?.subscribe();
          });
        }
      }
    );
  };
  unsubscribeAvailableConversations = () => {
    this.mAvailableConversations?.unsubscribe();
    this.mAvailableConversations = undefined;
  };

  get isAvailableConversation(): boolean {
    const count = this.availableConversations?.data?.length ?? 0;
    return count > 0;
  }

  acceptAvailableConversation() {
    when(
      () => this.isAvailableConversation,
      () => {
        const requestAvailableConversation = firebase
          .functions()
          .httpsCallable("requestAvailableConversation");
        requestAvailableConversation(1);
      }
    );
  }
}

export const workerProfileDataConverter = {
  toFirestore: function (data: WorkerProfileData) {
    return data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<WorkerProfileData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name ?? null,
      userId: data.userId ?? null,
    } as WorkerProfileData;
  },
};

export const workerProfileConverter = {
  toFirestore: function (workerProfile: WorkerProfile) {
    return workerProfile.data ?? {};
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot<WorkerProfileData>,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options);
    return WorkerProfile.createOrUpdateInstance(snapshot.ref.path, {
      id: snapshot.id,
      name: data.name ?? null,
      userId: data.userId ?? null,
    });
  },
};

export class WorkerProfiles {
  mCollectionRef: firebase.firestore.CollectionReference<WorkerProfile>;
  mQuery: firebase.firestore.Query<WorkerProfile> | null;
  mPath: string;
  mData: WorkerProfile[] | null | undefined;
  mSubscription: any;
  mSubscriptionCount: number;

  constructor(path: string) {
    makeAutoObservable(this);

    this.mCollectionRef = firebase
      .firestore()
      .collection(path)
      .withConverter(workerProfileConverter);

    this.mQuery = null;
    this.mPath = path;
    this.mSubscriptionCount = 0;
  }

  get collectionRef() {
    return this.mCollectionRef;
  }

  get query() {
    return this.mQuery;
  }
  set query(newValue: firebase.firestore.Query<WorkerProfile> | null) {
    this.mQuery = newValue;

    if (this.mSubscription) {
      this.mSubscription();
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<WorkerProfile>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  get path() {
    return this.mPath;
  }

  get data() {
    if (this.mData === undefined) {
      this.refetch();
    }

    return this.mData;
  }

  refetch() {
    if (!this.mSubscription) {
      (this.mQuery ?? this.collectionRef).get().then(
        action((snapshot: firebase.firestore.QuerySnapshot<WorkerProfile>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  subscribe() {
    ++this.mSubscriptionCount;
    if (this.mSubscription === undefined) {
      console.log(`subscribe to ${this.mPath}`);
      this.mSubscription = (this.mQuery ?? this.collectionRef).onSnapshot(
        action((snapshot: firebase.firestore.QuerySnapshot<WorkerProfile>) => {
          const data = snapshot.docs.map((doc) => doc.data());
          this.mData = data;
        })
      );
    }
  }

  unsubscribe() {
    if (--this.mSubscriptionCount < 0) {
      this.mSubscriptionCount = 0;
    }

    if (this.mSubscription && this.mSubscriptionCount == 0) {
      console.log(`unsubscribe from ${this.mPath}`);
      this.mSubscription();
      this.mSubscription = undefined;
    }
  }

  get loading() {
    return this.data === undefined;
  }

  get loaded() {
    return this.data !== undefined;
  }
}
