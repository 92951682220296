import React from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonProgressBar,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { people, chatbubbles, cog } from "ionicons/icons";
import ConversationPage from "./ConversationPage";
import ConversationsPage from "./ConversationsPage";
import CustomerPage from "./CustomerPage";
import CustomersPage from "./CustomersPage";
import SettingsPage from "./SettingsPage";
import { observer } from "mobx-react-lite";

const MainTabs: React.FC = observer(() => {
  const location = useLocation();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/" to="/conversations" />

        <Route exact path="/customers" component={CustomersPage} />
        <Route path="/customers/:id" component={CustomerPage} />

        <Route exact path="/conversations" component={ConversationsPage} />
        <Route path="/conversations/:id" component={ConversationPage} />

        <Route path="/settings" component={SettingsPage} />
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        hidden={
          !(
            location?.pathname.toLowerCase() === "/customers" ||
            location?.pathname.toLowerCase() === "/conversations" ||
            location?.pathname.toLowerCase() === "/settings"
          )
        }
      >
        <IonTabButton tab="customers" href="/customers">
          <IonIcon icon={people} />
          <IonLabel>Customers</IonLabel>
        </IonTabButton>
        <IonTabButton tab="conversations" href="/conversations">
          <IonIcon icon={chatbubbles} />
          <IonLabel>Conversations</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" href="/settings">
          <IonIcon icon={cog} />
          <IonLabel>Settings</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
});

export default MainTabs;
