import React, { useState, useRef } from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonTextarea,
  IonFooter,
  IonButton,
  IonInput,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonBadge,
  IonItemDivider,
  IonChip,
  useIonViewDidEnter,
} from "@ionic/react";
import { personCircle, send } from "ionicons/icons";
import { useParams } from "react-router";
import { Conversation } from "../models/Conversations";
import { observer } from "mobx-react-lite";
import MessageListItem from "../components/MessageListItem";
import { action } from "mobx";
import InternalMessageListItem from "../components/InternalMessageListItem";
import session from "../session";
import moment from "moment";
import { InternalMessage } from "../models/InternalMessages";
import { isTemplateMiddleOrTemplateTail } from "typescript";
import { Message } from "../models/Messages";
import CloseConversationButton from "../components/CloseConversationButton";

const ConversationPage = observer(() => {
  const [conversation, setConversation] = useState<Conversation>();
  const params = useParams<{ id: string }>();
  const [messageInput, setMessageInput] = useState("");
  const [internalMessageInput, setInternalMessageInput] = useState("");
  const [busySendingMessage, setBusySendingMessage] = useState(false);
  const [segment, setSegment] = useState<string | undefined>("customer");
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
  };

  useIonViewWillEnter(() => {
    const conversation = Conversation.createOrUpdateInstance(
      `conversations/${params.id}`
    );
    setConversation(conversation);
  });

  useIonViewDidEnter(() => {
    scrollToBottom();
  });

  const messages = conversation?.messages?.data?.reduce(
    (groups: Record<string, Message[]>, item) => {
      if (item.createdOn) {
        const dateString = moment(item.createdOn).format("YYYY-MM-DD");

        if (!groups[dateString]) {
          groups[dateString] = [];
        }

        groups[dateString].push(item);
      }

      return groups;
    },
    {}
  );

  const internalMessages = conversation?.internalMessages?.data?.reduce(
    (groups: Record<string, InternalMessage[]>, item) => {
      if (item.createdOn) {
        const dateString = moment(item.createdOn).format("YYYY-MM-DD");

        if (!groups[dateString]) {
          groups[dateString] = [];
        }

        groups[dateString].push(item);
      }

      return groups;
    },
    {}
  );

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/conversations"
            ></IonBackButton>
          </IonButtons>
            <IonIcon
                  style={{ height: "40px", width: "40px" }}
                  icon={personCircle}
                  color="primary"
                  slot="start"
            />
            <IonLabel slot="start">
              
                <h2>
                  {conversation?.customer?.fullName ??
                    conversation?.customer?.mobileNumber}
                </h2>
            </IonLabel>
          <IonButtons slot="end">
            <CloseConversationButton conversation={conversation} />
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonChip slot="end">{conversation?.owner?.name}</IonChip>
          <IonChip slot="end" color={conversation?.status == 'inactive' ? 'danger' : 'success'}>{conversation?.status == 'inactive' ? 'closed' : 'open'}</IonChip>
        </IonToolbar>
        <IonToolbar className="ion-no-border">
          <IonSegment
            onIonChange={(e) => {
              setSegment(e.detail.value);
              scrollToBottom();
            }}
            value={segment}
          >
            <IonSegmentButton value="customer">
              <IonLabel>Customer</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="internal" layout="icon-start">
              <IonLabel>Internal</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>

      <IonContent ref={contentRef} scrollEvents={true}>
        {conversation ? (
          <>
            <IonGrid hidden={segment != "customer"}>
              {messages &&
                Object.keys(messages).map((key) => (
                  <>
                    <IonItemDivider sticky>{key}</IonItemDivider>
                    {messages[key].map((item) => (
                      <MessageListItem key={item.id} message={item} />
                    ))}
                  </>
                ))}
            </IonGrid>

            <IonGrid hidden={segment != "internal"}>
              {internalMessages &&
                Object.keys(internalMessages).map((key) => (
                  <>
                    <IonItemDivider sticky>{key}</IonItemDivider>
                    {internalMessages[key].map((item) => (
                      <InternalMessageListItem
                        key={item.id}
                        internalMessage={item}
                      />
                    ))}
                  </>
                ))}
            </IonGrid>
          </>
        ) : (
          <div>Conversation not found</div>
        )}
      </IonContent>

      <IonFooter
        hidden={
          !(
            conversation?.status == 'active' &&
            segment == "customer" &&
            conversation?.ownerId == session.currentUser?.workerProfileId
          )
        }
      >
        <IonToolbar>
          <IonItem lines="none" style={{ height: "45px" }}>
            <IonInput
              disabled={busySendingMessage}
              value={messageInput}
              onIonChange={(e) => {
                setMessageInput(e.detail.value!);
              }}
            />

            <IonButton
              disabled={busySendingMessage}
              slot="end"
              color="primary"
              fill="solid"
              shape="round"
              style={{ height: "30px", width: "30px" }}
              onClick={action(() => {
                setBusySendingMessage(true);
                conversation?.sendMessage(messageInput);
                setMessageInput("");
                setBusySendingMessage(false);
                scrollToBottom();
              })}
            >
              <IonIcon icon={send}></IonIcon>
            </IonButton>
          </IonItem>
        </IonToolbar>
      </IonFooter>

      <IonFooter hidden={!(conversation?.status == 'active' && segment == "internal")}>
        <IonToolbar>
          <IonItem lines="none" style={{ height: "45px" }}>
            <IonInput
              disabled={busySendingMessage}
              value={internalMessageInput}
              onIonChange={(e) => {
                setInternalMessageInput(e.detail.value!);
              }}
            />

            <IonButton
              disabled={busySendingMessage}
              slot="end"
              color="primary"
              fill="solid"
              shape="round"
              style={{ height: "30px", width: "30px" }}
              onClick={action(() => {
                setBusySendingMessage(true);
                conversation
                  ?.sendInternalMessage(internalMessageInput)
                  .then(() => {
                    scrollToBottom();
                  });
                setInternalMessageInput("");
                setBusySendingMessage(false);
              })}
            >
              <IonIcon icon={send}></IonIcon>
            </IonButton>
          </IonItem>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
});

export default ConversationPage;
