import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonNote,
  IonIcon,
  IonThumbnail,
  IonSkeletonText,
} from "@ionic/react";

import {
  peopleOutline,
  personOutline,
  people,
  person,
  chatbubbles,
  cog,
  personCircle,
} from "ionicons/icons";
import moment from "moment";

import { observer } from "mobx-react-lite";
import { WorkItem } from "../models/WorkItems";

import session from "../session";
import React from "react";
import { Customer } from "../models/Customers";

interface CustomerListItemProps {
  customer: Customer;
}

const CustomerListItem: React.FC<CustomerListItemProps> = observer(
  ({ customer }) => {
    if (customer && customer.loaded) {
      return (
        <IonItem routerLink={`/customers/${customer.id}`} detail={false}>
          <IonAvatar
            slot="start"
            style={{
              height: "50px",
              width: "50px",
            }}
          >
            <IonIcon
              icon={personCircle}
              color="primary"
              style={{ fontSize: "50px" }}
            ></IonIcon>
          </IonAvatar>
          <IonLabel>
            <h2>{customer.fullName ?? customer.mobileNumber}</h2>
          </IonLabel>
        </IonItem>
      );
    }

    return (
      <IonItem>
        <IonAvatar
          slot="start"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <IonIcon
            icon={personCircle}
            color="medium"
            style={{ fontSize: "50px" }}
          ></IonIcon>
        </IonAvatar>
        <IonLabel>
          <h2>
            <IonSkeletonText animated style={{ width: "70%" }} slot="start" />
          </h2>
        </IonLabel>
      </IonItem>
    );
  }
);

export default CustomerListItem;
